var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.wrapper,
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: _vm.$utilities.getRippleOptions(_vm.rippleTheme),
          expression: "$utilities.getRippleOptions(rippleTheme)",
        },
      ],
      tag: "component",
      class: _vm.classes,
      attrs: {
        type: !_vm.href && !_vm.to ? _vm.type : false,
        href: !_vm.to ? _vm.href : false,
        to: !_vm.href ? _vm.to : false,
      },
      on: {
        click: function ($event) {
          !_vm.disabled && _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.$scopedSlots.iconPrepend
        ? _c(
            "span",
            { staticClass: "dp-button__icon dp-button__icon--prepend" },
            [_vm._t("iconPrepend")],
            2
          )
        : _vm._e(),
      _vm._t("default"),
      _vm.$scopedSlots.iconAppend
        ? _c(
            "span",
            { staticClass: "dp-button__icon dp-button__icon--append" },
            [_vm._t("iconAppend")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }