<template>
  <component
    :is="wrapper"
    :type="!href && !to ? type : false"
    :href="!to ? href : false"
    :to="!href ? to : false"
    :class="classes"
    v-ripple="$utilities.getRippleOptions(rippleTheme)"
    @click="!disabled && $emit('click', $event)">
    <span
      v-if="$scopedSlots.iconPrepend"
      class="dp-button__icon dp-button__icon--prepend">
      <slot name="iconPrepend" />
    </span>
    <slot />
    <span
      v-if="$scopedSlots.iconAppend"
      class="dp-button__icon dp-button__icon--append">
      <slot name="iconAppend" />
    </span>
  </component>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    // @values button | submit | reset
    type: {
      type: String,
      default: 'button'
    },

    href: {
      type: String,
      default: ''
    },

    to: {
      type: String,
      default: ''
    },

    styleType: {
      type: String,
      default: 'primary'
    },

    fluid: {
      type: Boolean,
      default: false
    },

    size: {
      // @values lg
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    isWhiteBorder: {
      type: Boolean,
      default: false
    }
  },

  setup: (props) => {
    const blockName = 'dp-button';
    const wrapper = computed(() => {
      if (props.href) {
        return 'a';
      } else if (props.to) {
        return 'router-link';
      }
      return 'button';
    });

    const styleTypeClass = computed(() =>
      props.styleType ? blockName + '--' + props.styleType : ''
    );
    const fluidClass = computed(() =>
      props.fluid ? blockName + '--' + 'fluid' : ''
    );
    const sizeClass = computed(() =>
      props.size ? blockName + '--' + props.size : ''
    );
    const disabledClass = computed(() =>
      props.disabled ? blockName + '--' + 'inactive' : ''
    );
    const whiteBorderClass = computed(() =>
      props.isWhiteBorder ? blockName + '--' + 'white-border' : ''
    );

    const classes = computed(() => {
      return [
        blockName,
        styleTypeClass.value,
        fluidClass.value,
        sizeClass.value,
        disabledClass.value,
        whiteBorderClass.value
      ];
    });

    const rippleTheme = computed(() => {
      if (props.styleType === 'disabled') {
        return null;
      } else if (!props.styleType || props.styleType.match(/tertiary/)) {
        return 'light';
      } else {
        return 'dark';
      }
    });

    return {
      wrapper,
      classes,
      rippleTheme
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 16px 9px;
  background-color: variables.$grey04;
  border: 1px solid variables.$white01;
  border-radius: 10em;
  font-size: 16px;
  line-height: 1.45;
  font-weight: bold;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &--fluid {
    width: 100%;
  }

  &--primary {
    background-color: variables.$dpBlack01;
    border-color: variables.$dpBlack01;
    color: variables.$dpWhite01;
  }

  &--secondary {
    background-color: variables.$dpWhite01;
    border-color: variables.$dpBlack01;
    color: variables.$dpBlack01;
  }

  &--tertiary {
    background-color: variables.$dpWhite01;
    border-color: variables.$dpGreyCc;
    color: variables.$dpGrey88;
  }

  &--disabled,
  &:disabled {
    background-color: variables.$dpGreyCc;
    border-color: variables.$dpGreyCc;
    color: variables.$dpGrey88;
  }

  &--lg {
    padding-top: 12px;
    padding-bottom: 11px;
    font-size: 18px;
  }

  &--sm {
    padding: 8px 8px 7px;
    background-color: variables.$dpWhite01;
    border-color: variables.$dpGrey66;
    border-radius: 0;
    color: variables.$dpGrey66;
    font-size: 12px;
    font-weight: normal;
  }

  &--white-border {
    border-color: variables.$dpWhite01;
  }
}

.dp-button__icon {
  display: grid;
  place-items: center;
  line-height: 1;

  &--prepend {
    margin-right: 0.5rem;
  }

  &--append {
    margin-left: 0.5rem;
  }
}
</style>
